const ACTIVE_BANNER_CLASS = 'activeNotifBanner'
export default (() => {
  class NotificationBanner {
    constructor({ selector }) {
      // There is only one banner per page
      this.elem = document.querySelector(selector)
      if (!this.elem) {
        return
      }
      this.addActiveBannerClass()
    }

    addActiveBannerClass() {
      document.documentElement.classList.add(ACTIVE_BANNER_CLASS)
    }
  }

  return NotificationBanner
})()
